import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useClickOutside from '../../../../hooks/use-click-outside.component';
import style from './menu.module.scss';
import SessionService from '../../../../../service/session/session.service';
import { useTranslation } from "react-i18next";
import HasMessagesContext from "../../../../../state/context/has-messages";

export default function Menu() {
    const [quickactionsOpen, setQuickactionsOpen] = useState(true);
    const hasMessagesContext = useContext(HasMessagesContext);
    if (!hasMessagesContext) throw new Error("HasMessagesContext must be used within a HasMessagesContextProvider");
    const { hasNewMessages } = hasMessagesContext;

    const quickactionRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState('');

    useEffect(() => {
        const path = location.pathname;
        if (path === '/') {
            setCurrentPage('home');
        } else if (path.includes('/explore')) {
            setCurrentPage('explore');
        } else if (path.includes('/inbox')) {
            setCurrentPage('inbox');
        } else if (path.includes('/marketplace')) {
            setCurrentPage('marketplace');
        }
    }, [location]);


    function InboxIcon() {
        return (
            <>
                <span className={`${style.inbox} ${currentPage === 'inbox' ? style.current : ''}`} />
                <div className={`${currentPage === 'inbox' ? style.lineIndicator : ''}`}></div>
                <span className={`${style.inbox_dot} ${!hasNewMessages && 'none'}`}></span>
                <span className={`${style.mobiletooltip} tooltiptext`}>Chats</span>
            </>
        );
    }

    return (
        <div className={style.menuWrapper}>
            <span  className={style.desktopMenu}>
                <span className={`${style.quickactions} ${quickactionsOpen ? '' : style.closed}`} ref={quickactionRef}>
                    <Link to='/explore' className={`${style.item} tooltip`}>
                        <span className={`${style.explore} ${currentPage === 'explore' ? style.current : ''}`} />
                        <div className={`${currentPage === 'explore' ? style.lineIndicator : ''}`}></div>
                        <span className={`${style.mobiletooltip} tooltiptext`}>Explore</span>
                    </Link>
                    <Link to='/inbox' className={`${style.item} tooltip`}>
                        <InboxIcon />
                    </Link>
                    <Link to='/marketplace' className={`${style.item} tooltip`}>
                        <span className={`${style.marketplace} ${currentPage === 'marketplace' ? style.current : ''}`} />
                        <div className={`${currentPage === 'marketplace' ? style.lineIndicator : ''}`}></div>
                        <span className={`${style.mobiletooltip} tooltiptext`}>Marketplace</span>
                    </Link>
                </span>
            </span>
            <div className={style.mobileMenu}>
                <Link to='/explore' className={`${style.item} ${currentPage === 'explore' ? style.current : ''}`}>
                    <span className={style.explore} />
                    <span>Explore</span>
                </Link>
                <Link to='/inbox' className={`${style.item} ${currentPage === 'inbox' ? style.current : ''}`}>
                    <InboxIcon />
                    <span>Chats</span>
                </Link>
                <Link to='/marketplace' className={`${style.item} ${currentPage === 'marketplace' ? style.current : ''}`}>
                    <span className={style.marketplace} />
                    <span>Marketplace</span>
                </Link>
            </div>
        </div>
    );
}